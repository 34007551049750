@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import './../base/variables';
@import './../helpers/mixins';


.block-infobox-section-wrap {
  padding: 48px 0 0;
  background-image: url('./../../assets/img/abstract-white-wave.png');
  background-repeat: repeat-x;
  background-position: bottom center;
  .infobox-desc {
    margin-bottom: 48px;
  }
  .infobox-box-item {
    margin: 48px 0;
    padding: 91px 24px 48px;
    border: 1px solid $gray-300;
    border-radius: 30px;
    position: relative;
    background-color: $white;
    .icon-wrap {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $white;
      max-width: 100px;
      
    }
    .description {
      position: relative;
      padding-bottom: 36px;
      margin-bottom: auto;
    }
    ul {
      list-style: none;
      li {
        margin-bottom: 18px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          height: 100%;
          width: 30px;
          right: 100%;
          background-image: url('./../../assets/img/icon1.svg');
          background-position: center left;
          background-repeat: no-repeat;
        }
      }
    }
    .button-wrap {
      text-align: center;
    }
    &.green {
      ul {
        li {
          &::before {
            background-image: url('./../../assets/img/icon1.svg');
          }
        }
      }
    }
    &.orange {
      ul {
        li {
          &::before {
            background-image: url('./../../assets/img/icon3.svg');
          }
          
        }
      }
      .btn-primary {
        &:hover {
          background-color: $orange;
        }
      }
    }
    &-row {
      margin: 0;
      margin-bottom: 32px;
      padding: 48px 32px;
      .infobox-header {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        .icon-wrap {
          position: static;
          margin-right: 24px;
          transform: none;
          max-width: 48px;
        }
        h2 {
          margin-bottom: 0;
        }
      }
      .description {
        padding-bottom: 24px;
      }
      .button-wrap {
        margin-bottom: 36px;
      }
    }
  }
  &.no-bg {
    background-image: none;
  }
  @include media-breakpoint-up(sm) { 
    
  }
  @include media-breakpoint-up(md) { 
    padding: 122px 0 72px;
    .infobox-box-item {
      margin: 0;
      padding: 130px 48px 48px;
      border: 1px solid $gray-300;
      border-radius: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .icon-wrap {
        position: absolute;
        top: 0;
        left: 48px;
        transform: translateY(-50%);
        background-color: $white;
        max-width: 140px;
        
      }
      .button-wrap {
        text-align: left;
      }
      &-row {
        margin-bottom: 32px;
        padding: 48px 32px 24px;
        height: auto;
        .infobox-header {
          h2 {
            font-size: toRem(32);
          }
        }
      }
    }
  }
}
